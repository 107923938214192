import React from 'react'
import Layout from '../components/layout'

const CoffeePage = () => (
  <Layout>
    <h1>Coffee</h1>
    <p>You've found my secret...</p>
  </Layout>
)

export default CoffeePage
